var num = 0;
window.removeImageProduct = function(event) {
  numberImages()
  let target = event.target
  let element = target.parentNode
  if(element.firstElementChild.dataset["persisted"] == 'true') {
    element.classList.toggle('d-none');
    element.innerHTML += '<input type="hidden" name="store_product[images_attributes][][_destroy]" value=true>';
  } else {
    element.remove();
  }
}

function numberImages() {
  if (num == 0) {
    num = document.getElementsByClassName('preview-image').length
  }
}

window.readImageProductList = function(event) {
  if (window.File && window.FileList && window.FileReader) {
      numberImages()
      var inputPro = event.target
      var files = event.target.files;
      var output = document.getElementsByClassName("preview-images-zone")[0];
      for (let i = 0; i < files.length; i++) {
          var file = files[i];
          if (!file.type.match('image')) continue;

          var picReader = new FileReader();
          picReader.addEventListener('load', function (event) {
              var picFile = event.target;
              var div = document.createElement('div');
              div.className = 'preview-image preview-show-' + num;
              div.innerHTML = '<div class="image-cancel" data-id="' + num + '" data-persisted="false" onclick="removeImageProduct(event)">x</div>' +
                              '<div class="image-zone"><img id="pro-img-' + num + '" src="' + picFile.result + '"></div>'+
                              '<input name="store_product[images_attributes][][id]" type="hidden" id="product_images_attributes_id"></input>'
              var inputImage = inputPro.cloneNode(true);
              inputPro.parentElement.appendChild(inputImage);
              inputPro.name = 'store_product[images_attributes][][image]';
              inputPro.id = 'store_product_images_attributes_image';
              div.appendChild(inputPro);
              output.appendChild(div);
              num = num + 1;
            });

          picReader.readAsDataURL(file);
      }
  } else {
      console.log('Browser not support');
  }
}
