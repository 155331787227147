// show Product
window.masteredProductShow = function(event, relative_path) {
  jsRenderShowProduct.innerHTML = '';
  toggleSpinner('js-load-spinner');
  selected = event.srcElement;
  while(true) {
    if (!selected.classList.contains('list-group-item')) {
      selected = selected.parentNode
    }
    else {
      break;
    }
  }
  products = Array.from(document.getElementsByClassName('list-group-item'));
  products.forEach(function(el){
    el.classList.remove("active");
  });

  selected.classList.add("active");

  remoteLoader(relative_path, {})
}

// Edit Product
// Adicionando imagem
window.addImage = function(event) {
  var newImages = document.getElementById('js-prev-imgs');
  var url = URL.createObjectURL(event.target.files[0]);
  var col = document.createElement("div");
  col.classList.add("col-4");
  var output = document.createElement("img");
  output.style = "max-width: 160px;";
  readImageURL(output, url);
  col.appendChild(output);
  newImages.innerHTML = col.innerHTML;
}

// Removendo imagem
window.removeImage = function(element) {
  var form = document.getElementById('js-edit-product-form');
  var id = element.dataset["id"];
  form.innerHTML += `<input type="hidden" name="product[images_attributes][][id]" value="${id}">`;
  form.innerHTML += `<input type="hidden" name="product[images_attributes][][_destroy]" value=true>`;
  document.getElementById(`js-col-image-id-${id}`).classList.add("d-none");
}

// Renderizando imagem
window.readImageURL = function(output, url) {
  output.src = url
  output.onload = function() {
    URL.revokeObjectURL(output.src) // free memory
  }
}

// Prepara modal de deleção
window.deleteStoreProduct = function(productId, id) {
  var button = document.getElementById('js-delete-store-product');
  button.href = `/ecommerce/admin/products/${productId}/store_products/${id}`
  var modal = document.getElementById('modal-store-product-destroy');
  new bootstrap.Modal(modal).show();
}

// Validação do formulário
window.validFormProducts = function(event, element) {
  event.preventDefault();
  let button = event.srcElement;
  let form = document.getElementById('jsFormEditProduct');
  let images = document.querySelectorAll('.preview-image:not(.d-none)');
  let weightField = document.getElementById('JsUnitWeightValue');
  button.disabled = true;
  button.value = 'Salvando'
  removeValidityToElement(weightField);

  if(images.length == 0){
    SweetAlert.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Selecione pelo menos uma imagem!'});
      button.disabled = false;
      button.value = 'Salvar';
    return;
  }

  for (const el of form.querySelectorAll("[required]")) {
    if (!el.reportValidity()) {
      button.disabled = false;
      button.value = 'Salvar';
      return;
    }
  }

  if (element.checked && weightField.value == '') {
    validateToElement(weightField, 'Preencha este campo.')
    button.disabled = false;
    button.value = 'Salvar';
    return;
  };

  form.submit();
}

window.jsCheckWeighable = function(element, unitweight, event) {
  let unitWeight = unitweight.querySelector('input');
  removeValidityToElement(unitWeight);

  if (element.checked && unitWeight.value == '') {
    validateToElement(unitWeight, 'Preencha este campo.')
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  };
}

window.jsCheckCodeLength = function(element, event) {
  let codeLength = element.querySelector('input');
  removeValidityToElement(codeLength);

  if (codeLength.value.length > '13') {
    validateToElement(codeLength, 'O código deve possuir no máximo 13 caracteres.')
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  };
}
