// Operation Time
window.validateFormOperationTimeNew = function(event) {
  let daysOfTheWeek = Array.from(document.getElementsByName('operation_times[days_of_the_week][]'));
  let timeRangeType = Array.from(document.getElementsByName('operation_times[time_range_type]'));
  let purchaseTypes = Array.from(document.getElementsByName('operation_times[purchase_types][]'));
  let startTime = document.getElementById('operation_times_start_time');
  let endTime = document.getElementById('operation_times_end_time');
  removeValidityToElement(daysOfTheWeek[3]);
  removeValidityToElement(timeRangeType[0]);
  removeValidityToElement(purchaseTypes[0]);
  removeValidityToElement(endTime);

  if(!(daysOfTheWeek.reduce((acc, curr) => acc || curr.checked, false))){
    validateToElement(daysOfTheWeek[3], 'Selecione pelo menos um dia da semana!');
    returnForm()
  };
  
  if(!(timeRangeType.reduce((acc, curr) => acc || curr.checked, false))){
    validateToElement(timeRangeType[0], 'Selecione um tipo de intervalo de atendimento!');
    returnForm()
  };
  
  if(!(purchaseTypes.reduce((acc, curr) => acc || curr.checked, false))){
    validateToElement(purchaseTypes[0], 'Selecione a um tipo de compra para a regra!');
    returnForm()
  };

  if(endTime.value != '' && startTime.value >= endTime.value){
    validateToElement(endTime, 'O Horário de término tem que ser maior que o de início!');
    returnForm()
  };

  function returnForm() {
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  };
};

function activeOperationTimeButtonDestroy(flag) {
  document.getElementById('js-operation-time-button-destroy').disabled = flag;
}


window.addEventListener("load", () => {
  var form = document.getElementById('js-form-operation-time-destroy');
  if(form != undefined) {
    const cards = document.getElementsByName("operation_time_ids[]");
    cards.forEach((element) => {
      element.addEventListener("click", (event) => {
        var arrayCards= Array.from(cards)
        activeOperationTimeButtonDestroy(!arrayCards.reduce((acc, curr) => acc || curr.checked, false));
      });
    });
  }
});
