window.readImageTemporaryProduct = function(event) {
  var url = URL.createObjectURL(event.target.files[0]);
  var output = document.createElement("img");
  output.classList.add('w-100')
  readImageURL(output, url);
  event.target.nextElementSibling.innerHTML = '';
  event.target.nextElementSibling.appendChild(output);
  event.target.nextElementSibling.innerHTML+= `(${output.width}, ${output.height})`;
}

window.posCreateProduct = function(isCreated = true) {
  if (!isCreated) {
    error = document.getElementById('js-message-error');
    error.innerHTML = 'Não foi possivel criar o produto, tente novamente com outra imagem!';
    return;
  }
  productSelected = document.querySelector('.list-group-item.active')
  if (productSelected.nextElementSibling) {
    productSelected.nextElementSibling.click()
  }
  productSelected.parentNode.removeChild(productSelected);
}

window.changeCheckIndustryCode = function(el, value) {
  input = document.getElementById('master_product_code')
  if (el.checked) {
    input.classList.remove('d-none');
    input.value = value;
  }
  else {
    input.classList.add('d-none');
    input.value = '';
  }
}

window.changeCheckWeighable = function(el, value = '') {
  input = document.getElementById('js-input-unit-weight')
  if (el.checked) {
    input.classList.remove('d-none');
    master_product_unit_weight.value = value;
  }
  else {
    input.classList.add('d-none');
    master_product_unit_weight.value = '';
  }
}
