window.initializeDynamicSelect = function (mainSelectId, dependentSelectId, fetchPath, filterKey, dependentSearchPlaceholder) {

  var dependentSlimSelect = new SlimSelect({
    select: `#${dependentSelectId}`,
    settings: {
      searchPlaceholder: `${dependentSearchPlaceholder}`,
    }
  });

  var allOptions = dependentSlimSelect.getData();

  function updateSelectOptions(newOptions) {
    dependentSlimSelect.setData([]);
    dependentSlimSelect.setData(newOptions.map(option => ({
      text: option.text,
      value: option.value
    })));
  }

  document.getElementById(mainSelectId).addEventListener('change', function () {
    const mainSelectValue = this.value;

    if (mainSelectValue) {
      fetch(`${fetchPath}?${filterKey}=${mainSelectValue}`)
        .then(response => response.json())
        .then(data => {
          const newOptions = data.map(item => ({
            text: item[1],
            value: item[0]
          }));

          updateSelectOptions(newOptions);
        })
        .catch(error => console.error('Error:', error));
    } else {
      updateSelectOptions(allOptions);
    }
  });
};
