window.checkedPermissions = function(profile, defaultPermissions, storesEditable) {
  permissions = defaultPermissions[`${profile}_permissions`];

  document.getElementsByName('permissions[]').forEach(function(el) {
    el.checked = false;
    el.disabled = profile == 'neither';
  });

  if (profile !== 'neither') {
    if (storesEditable) {
      document.getElementById('jsInputStores').disabled = false;
    }
    for (var i = 0; i < permissions.length; i++) {
      let element_permission = document.querySelector(`.js_checkbox_permission_${permissions[i]}`);
      if (element_permission) {
        element_permission.checked = true;
        element_permission.disabled = true;
      }
    }
  }
  else {
    document.getElementById('jsInputStores').disabled = true;
  }
}
