window.validFormTopAndCategoryBanners = function(event) {
  let activatedAtField = jsActivatedAt.querySelectorAll('input')[1]
  let inactivatedAtField = jsInactivatedAt.querySelectorAll('input')[1]
  let jsCategoryChoices = document.getElementById('jsBannerChoises')
  let topBanner = document.getElementById('jsTopBanner')
  let categoryBanner = document.getElementById('jsCategoryBanner')
  let validateRegexLink = /^(ftp|http|https):\/\/[^ "]+$/.test(jsInputLink.value)
  let referenceLinkType = document.getElementById('jsInputReferenceLinkType')
  let referenceType = document.getElementById('jsInputReferenceType')
  let referenceElement = document.getElementById('jsInputReferenceElement')
  removeValidityToElement(activatedAtField);
  removeValidityToElement(inactivatedAtField);

  if(jsCategoryChoices != undefined && jsCategoryChoices != null) {
    removeValidityToElement(jsCategoryChoices.querySelector('input'));
    if(jsCategoryChoices.querySelector('select').length == 0){
      validateToElement(jsCategoryChoices.querySelector('input'), 'Selecione pelo menos um tipo de categoria para a regra.');
      returnForm()
    }
  }

  if(jsInactivatedDateField.value != '' && jsActivatedDateField.value >= jsInactivatedDateField.value) {
    validateToElement(inactivatedAtField, 'A data de inativação deve ser maior quer a data de ativação.');
    returnForm()
  }

  if (typeof jsDropMessageRegion != 'undefined') {
    SweetAlert.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Selecione uma imagem!' });
    jsDropMessageRegion.focus();
    returnForm();
  }

  if (topBanner != 'undefined' && topBanner != null) {
    removeValidityToElement(jsInputLink)
    if (referenceLinkType.value == 'external' && validateRegexLink != true && jsInputLink.value != '') {
      validateToElement(jsInputLink, 'O link deve estar no formato especificado')
      returnForm()
    }
  }
  
  if (topBanner != 'undefined' && topBanner != null) {
    removeValidityToElement(referenceElement)
    if (referenceLinkType.value == 'internal' && referenceType.value != '' && referenceElement.value == '') {
      validateToElement(referenceElement, 'Caso selecione algum tipo de referência é necessário informar um elemento')
      returnForm()
    }
  }

  if (categoryBanner != 'undefined' && categoryBanner != null) {
    removeValidityToElement(jsInputLink)
    if (validateRegexLink != true && jsInputLink.value != '') {
      validateToElement(jsInputLink, 'O link deve estar no formato especificado')
      returnForm()
    }
  }

  validateBannersDayAndTime(event);

  function returnForm() {
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  }
}
