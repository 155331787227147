window.checkSelectStoreCategory = function() {
  var form = jsFormStoreCategories;
  var categories = form.querySelectorAll('input[name="master_subcategories_ids[]"]:checked');

  var confirmer = false;

  categories.forEach(function(category) {
    if (category.dataset.status != categories[0].dataset.status) {
      confirmer = true;
    }
  });

  if (confirmer) {
    SweetAlert.fire(
      {
        title: 'Status diferentes!',
        text: 'Existem status divergentes selecionados, ao confirmar os ativos serão desativados e os inativos serão ativados!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, eu tenho certeza!',
        cancelButtonText: 'Não, cancelar!',
        focusConfirm: true
      }).then((result) => {
        if (result.isConfirmed) {
          form.submit();
        }
      }
    );
  }
  else {
    form.submit();
  }
}
