window.validateFormMasterCategory = function(event) {
  var button = event.srcElement;
  button.disabled = true;
  event.preventDefault();
  var form = document.getElementById('js-form-master-category');
  var inputImage = document.querySelector('.js-input-image');

  for (const element of form.querySelectorAll("[required]")) {
    if (!element.reportValidity()) {
      button.disabled = false;
      return;
    }
  }

  if (inputImage.value == '') {
    var message = document.getElementById('js-image-message');
    message.classList.remove('d-none');
    button.disabled = false;
    return;
  }

  button.textContent = 'Salvando...';
  form.submit();
}
