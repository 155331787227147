window.changeAllElementsBackgroundColor = function() {
 elements = document.querySelectorAll(`input[type="checkbox"]`);

 elements.forEach(element => { changeElementBackgroundColor(element); });
}

window.changeElementBackgroundColor = function(element) {
  if (element.checked) {
    element.parentElement.classList.add('cb-selected-color');
  } else {
    element.parentElement.classList.remove('cb-selected-color');
  }
}
