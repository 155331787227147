// Operation Time
window.validateLinkedStores = function(event) {
  let storesLinkedProducts = Array.from(document.getElementsByName('store_product[store_ids][]'));
  removeValidityToElement(storesLinkedProducts[0]);

  if(!(storesLinkedProducts.reduce((acc, curr) => acc || curr.checked, false))){
    validateToElement(storesLinkedProducts[0], 'Selecione pelo menos uma loja!');
    returnForm()
  };

  function returnForm() {
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  };
};

window.jsToggleCheckAllStores = function(source) {
  let checkboxes = document.querySelectorAll('input[name="store_product[store_ids][]"]');

  for(var i = 0, n = checkboxes.length; i < n; i++){
    if (source.checked != checkboxes[i].checked) {
      checkboxes[i].click();
      source.checked ? jsCheckBoxText.innerText = 'Desmarcar todas as lojas' : jsCheckBoxText.innerText = 'Selecionar todas as lojas'
    }
  }
};
