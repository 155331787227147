window.initializeSortable = function(lists, group) {
  lists.forEach((list) => {
    new Sortable(list,
      {
        removeCloneOnHide: true,
        group: { name: group, put: [group] },
        onMove: function (evt) {
          if (evt.to !== evt.from) {
            evt.to.append(evt.dragged);

            return false;
          }
        }
      }
    );
  });
}
