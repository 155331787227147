window.validateToElement = function(element, message) {
  element.setCustomValidity(message);
  element.reportValidity();
  element.classList.remove('is-valid');
  element.classList.add('is-invalid');
}

window.removeValidityToElement = function(element) {
  element.setCustomValidity('');
  element.reportValidity();
  element.classList.remove('is-invalid');
}
