window.loadDatePicker = function() {
  let dateInputs = document.getElementsByClassName('js-date-picker')

  Array.from(dateInputs).forEach(e => {
    hash = { locale: 'pt', enableTime: false, altInput: true, altFormat: 'd/m/Y', allowInput: true }

    mergeAttributes(e, hash)

    flatpickr(e, hash);
  });

  let datetimeInputs = document.getElementsByClassName('js-datetime-picker')

  Array.from(datetimeInputs).forEach(e => {
    hash = { locale: 'pt', enableTime: true, altInput: true, altFormat: 'd/m/Y H:i', time_24hr: true,
             allowInput: true }

    mergeAttributes(e, hash)

    flatpickr(e, hash);
  });

  let timeInputs = document.getElementsByClassName('js-time-picker')

  Array.from(timeInputs).forEach(e => {
    hash = { locale: 'pt', noCalendar: true, enableTime: true, dateFormat: 'H:i', time_24hr: true,
             allowInput: true }

    mergeAttributes(e, hash)

    flatpickr(e, hash);
  });

  function mergeAttributes(element, hash) {
    let minDatetime = element.getAttribute('min')
    let inline = element.getAttribute('inline')
    if (minDatetime != '') { hash['minDate'] = minDatetime }
    if (inline != '') { hash['inline'] = inline }
  }
};
