window.limitCharactersInputNumber = function() {
  let inputsNumber = document.querySelectorAll('input[type="number"]');

  inputsNumber.forEach(function(input) {
    const max = input.getAttribute('max');

    if (max !== null) {
      input.addEventListener('input', function() {
        let limit = input.max.length
        let value = input.value
        if (value.length > limit) {
          input.value = value.slice(0, limit);
        }
      });
    }
  });
};
