window.initializeQuill = function(element, hiddenField, toolbar) {
  const toolbars = {
    defaultToolbar: [
      [{ 'size': ['small', false, 'large', 'huge'] }], ['bold', 'italic', 'underline', 'strike'],
      ['link', 'blockquote', 'code-block'], [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }], [{ 'color': [] }, { 'background': [] }],
    ],
    policyToolbar: [
      ['bold', 'italic'], ['link', 'blockquote', 'image'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], [{ 'align': [] }]
    ]
  }

  const selectedToolbar = toolbars[toolbar] || toolbars.default;

  var quill = new Quill(element, {
    modules: {
      toolbar: selectedToolbar
    },
    theme: 'snow'
  });
  let delta = quill.clipboard.convert(hiddenField.value)

  quill.setContents(delta, 'silent')

  return quill
};
