window.initializeInputEmojiPicker = function(element, inputField) {
  let picker = new EmojiButton();

  picker.on('emoji', selection => {
    cut_position = inputField.selectionStart
    inputField.value = inputField.value.substring(0, cut_position) +
    selection.emoji + inputField.value.substring(cut_position, inputField.value.length);
    inputField.dispatchEvent(new Event('input'))
  });

  element.addEventListener('click', () => picker.togglePicker(element));
};

window.initializeInputEmojiPickerWithQuill = function(element, quillElement) {
  let quill = Quill.find(quillElement)

  let picker = new EmojiButton();

  picker.on('emoji', selection => {
    insert_position = quill.getLength() - 1;
    quill.insertText(insert_position, selection.emoji)
  });

  element.addEventListener('click', () => picker.togglePicker(element));
};
