function addRule() {
  let listRules = document.querySelector('#fieldsetContainer')
  lastRule = listRules.lastElementChild;
  lastId = lastRule.getAttribute('id')

  let newId = parseInt(lastId) + 1

  lastFeeType = lastRule.querySelector('#feeType').value
  lastValue = lastRule.querySelector('.jsMaskMoney').value
  lastMax = lastRule.querySelector('#maxCountOrders').value
  lastMin = lastRule.querySelector('#minCountOrders').value
  range = lastMax - lastMin

  let newMin = parseInt(lastMax) + 1
  let newMax = newMin + range

  newRule = lastRule.cloneNode(true)
  newRule.setAttribute('id', newId)

  listRules.appendChild(newRule);
  newRule.querySelector('#feeType').value = lastFeeType || ''
  newRule.querySelector('.jsMaskMoney').value = lastValue.replace(/\./g, '').replace(/\,/g, '.');
  newRule.querySelector('#minCountOrders').value = newMin || ''
  newRule.querySelector('#maxCountOrders').value = newMax || ''

  if (listRules.childElementCount == 2) {
    document.querySelectorAll('.js-remove-field').forEach((el) => {
      el.classList.remove('d-none')
    });
  }

  limitCharactersInputNumber();
  applyMaskPhoneNumber(newRule.querySelector('.jsMaskMoney'));
}

function removeRule(element) {
  element.parentNode.parentNode.parentNode.remove()
  if (document.querySelector('#fieldsetContainer').childElementCount == 1) {
    document.querySelector('.js-remove-field').classList.add('d-none')
  }
}

window.initializeManageContracts = function() {
  window.addRule = addRule;
  window.removeRule = removeRule;
}

window.validateContractForm = function(event) {
  removeValidityToElement(jsInactivatedAtField.nextElementSibling);
  if(jsInactivatedAtField.value != '' && jsActivatedAtField.value >= jsInactivatedAtField.value) {
    validateToElement(jsInactivatedAtField.nextElementSibling, 'A data de inativação deve ser maior quer a data de ativação!')
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  }
}
