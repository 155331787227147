window.initializeDropRegion = function() {
  // região de click ou de drop de arquivos
  var dropRegions = document.getElementsByClassName('drop-region');

  Array.from(dropRegions).forEach(dropRegion => {
    var input = dropRegion.querySelector('#file-input');
    // abre o seletor de arquivos quando clica na região de drop
    dropRegion.addEventListener('click', function() {
      input.click();
    });

    // troca a pre-visualização da imagem ao trocar a imagem
    input.addEventListener('change', function() {
      file = input.files[0]
      handleFiles(file, dropRegion);
    });

    dropRegion.addEventListener('dragenter', preventDefault)
    dropRegion.addEventListener('dragleave', preventDefault)
    dropRegion.addEventListener('dragover', preventDefault)
    dropRegion.addEventListener('drop', preventDefault)
    dropRegion.addEventListener('drop', handleDrop);
  })
}

function preventDefault(e) {
  e.preventDefault();
  e.stopPropagation();
}

function handleDrop(e) {
  var input = e.currentTarget.querySelector('#file-input');
  var files = e.dataTransfer.files;

  input.files = files
  handleFiles(files[0], e.currentTarget);
}

function handleFiles(file, dropRegion ) {
  if (validateImage(file)) previewImage(file, dropRegion);
}

function validateImage(image) {
  // validação de tipo da imagem
  var validTypes = ['image/jpeg', 'image/pjpeg', 'image/jpg', 'image/x-png', 'image/png',
                    'image/gif'];
  if (validTypes.indexOf( image.type ) === -1) {
    alert('Tipo de arquivo inválido');
    return false;
  }

  // validação do tamanho da imagem
  var maxSizeInBytes = 8000000;
  if (image.size > maxSizeInBytes) {
    alert('Arquivo muito grande');
    return false;
  }

  return true;
}

function previewImage(image, dropRegion) {
  dropMessageRegion = dropRegion.querySelector('.jsDropMessageRegion')
  if( dropMessageRegion != null) dropMessageRegion.classList.add('d-none')
  // container
  var imgView = document.createElement('div');
  imgView.className = 'image-view';

  imagePreviewRegion = dropRegion.querySelector('.image-preview-region');
  imagePreviewRegion.innerHTML = ''
  imagePreviewRegion.appendChild(imgView);

  // previewing image
  var img = document.createElement('img');
  imgView.appendChild(img);

  // read the image...
  var reader = new FileReader();
  reader.onload = function(e) {
    img.src = e.target.result;
  }
  reader.readAsDataURL(image);
}
