window.bannerAttachmentLinkCheckedSet = function(element) {
  let requiredFields = element.querySelectorAll('[required]');

  for (var i = 0; i < requiredFields.length; i++) {
    requiredFields[i].removeAttribute('required');
  }

  if(element.querySelector('#jsInputReferenceLinkType').value == 'internal') {
    element.querySelector('#jsInternalLinkSelected').classList.remove('d-none')
    element.querySelector('#jsExternalLinkSelected').classList.add('d-none')
    element.querySelector('#jsInputReferenceType').setAttribute('required', 'required')
    element.querySelector('#jsInputReferenceElement').setAttribute('required', 'required')
  }
  else if(element.querySelector('#jsInputReferenceLinkType').value == 'external') {
    element.querySelector('#jsExternalLinkSelected').classList.remove('d-none')
    element.querySelector('#jsInternalLinkSelected').classList.add('d-none')
    element.querySelector('#jsInputLink').setAttribute('required', 'required')
  }
  else {
    element.querySelector('#jsInternalLinkSelected').classList.add('d-none')
    element.querySelector('#jsExternalLinkSelected').classList.add('d-none')
  }
}

window.bannerAttachmentSetReferencesType = function (element) {
  let inputReferenceElement = element.querySelector('#jsInputReferenceElement')
  let inputReferenceType = element.querySelector('#jsInputReferenceType')
  let options = inputReferenceElement.options
  for (var i=options.length; i--;) {
    inputReferenceElement.removeChild(options[i]);
  }

  if(inputReferenceType.value != '') {
    selectedOption = inputReferenceElement.dataset['selectedOption']
    optionsForSelect = JSON.parse(inputReferenceElement.dataset['optionsForSelect'])
    inputReferenceElement.options.add(new Option('Selecione o elemento de referência', '', true, true));
    for (var key in optionsForSelect) {
      if (key == selectedOption) {
        inputReferenceElement.options.add(new Option(optionsForSelect[key], key, true , true));
      }
      else {
        inputReferenceElement.options.add(new Option(optionsForSelect[key], key));
      }
    }
  }
  else {
    inputReferenceElement.options.add(new Option('Selecione o tipo de referência', '', true, true));
  }
}
