window.graphOrders = function(dataStoresCount, dataStoresValue, dataAverageTicket) {
  const ctx1 = document.getElementById('js_chart_report_orders_store_count');
  new Chart(ctx1, {
    type: 'doughnut',
    data: dataStoresCount,
    options: { plugins: { legend: { position: 'left' } } }
  });

  const ctx2 = document.getElementById('js_chart_report_orders_store_value');
  new Chart(ctx2, {
    type: 'doughnut',
    data: dataStoresValue,
    options: {
      plugins: { legend: { position: 'left' },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed);
              return label;
            }
          }
        }
      }
    }
  });

  const ctx3 = document.getElementById('js_chart_report_orders_day_value');
  new Chart(ctx3, {
    type: 'bar',
    data: dataAverageTicket,
    options: {
      plugins: { legend: { display: false },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
              return label;
            }
          }
        }
      }
    }
  });
}
