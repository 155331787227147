window.buttonRecurrentableForm = function() {
  let button = document.querySelector('#jsRecurrentableBannerButton');
  let form = document.querySelector('#jsRecurrentableBannerForm');
  showOrHideForm(button, form);

  button.addEventListener('change', function() {
    showOrHideForm(button, form);
    removeValues();
  });
}

function showOrHideForm(button, form) {
  form.style.display = button.checked ? '' : 'none';
}

function removeValues() {
  let allTimeCheckbox = document.querySelector('#jsBannerAllTime');
  allTimeCheckbox.checked = false;
  let allDaysCheckBox = document.querySelector('#jsBannerAllDay');
  allDaysCheckBox.checked = false;
  
  checkboxAllTheTime(false);
  checkboxAllDays(false);
}

window.checkboxAllTheTime = function(state) {
  let startTimeField = document.querySelector('#jsBannerStartTime');
  let endTimeField = document.querySelector('#jsBannerEndTime');

  startTimeField.disabled = state;
  endTimeField.disabled = state;

  if (state) {
    startTimeField.value = '00:00';
    endTimeField.value = '23:59';
  } else {
    startTimeField.value = '';
    endTimeField.value = '';
  }
}

window.checkboxAllDays = function(state) {
  let days = document.querySelectorAll('input[id^="days_of_week_"]');
  days.forEach(function(day) {
    day.checked = state;
    day.disabled = state;
  });

  changeAllElementsBackgroundColor();
}

window.validateBannersDayAndTime = function (event) {
  let recurrentable = document.getElementById('jsRecurrentableBannerButton');
  let startTimeField = document.getElementById('jsBannerStartTime');
  let endTimeField = document.getElementById('jsBannerEndTime');
  let days = document.querySelectorAll('input[id^="days_of_week_"]:checked');
  removeValidityToElement(startTimeField);
  removeValidityToElement(endTimeField);

  if (recurrentable.checked) {
    if (days.length == 0) {
      SweetAlert.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Selecione pelo menos um dia da semana' });
      returnForm()
    }
    if (startTimeField.value == '') {
      validateToElement(startTimeField, 'Informe um hórario inicial')
      returnForm()
    }
    if (endTimeField.value == '') {
      validateToElement(endTimeField, 'Informe um hórario final')
      returnForm()
    }
    if (startTimeField.value != '' && endTimeField.value != '') {
      if (startTimeField.value >= endTimeField.value){
        validateToElement(startTimeField, 'Hórario inicial não pode ser maior que o final')
        returnForm()
      }
    }
  }

  enableTimeAndDaysInputs();

  function returnForm() {
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  }

  function enableTimeAndDaysInputs(){
    let isInvalid = document.querySelectorAll('.is-invalid')

    if (isInvalid.length == 0) {
      startTimeField.disabled = false;
      endTimeField.disabled = false;
  
      days.forEach(function(day) {
        day.disabled = false;
      });
    }
  }
}
