document.addEventListener('DOMContentLoaded', () => {
  let elements = document.getElementsByClassName('js-remote-component')

  Array.from(elements).forEach(e => {
    let url = e.dataset["url"];
    let params = e.dataset["params"];

    if (url) { remoteLoader(url, params) }
  });
});

window.remoteLoader = function(url_reference, params) {
  Rails.ajax({
    url: url_reference,
    type: 'GET',
    data: new URLSearchParams(params).toString(),
    error: (data) => {
      displayNoneSpinner()
    }, success: (data) => {
      displayNoneSpinner()
    }
  });
}

function displayNoneSpinner() {
  var spinner = document.getElementById('js-load-spinner')
  if (spinner) { spinner.classList.add('d-none') }
}
