window.validateMiniBannersForm = function(event, form) {
  removeValidityToElement(jsInactivatedAtField.nextElementSibling);

  for(var i = 1; i < 5; i++){
    let externalLinkField = document.getElementsByClassName(`jsLinkField${i}`)[0]
    removeValidityToElement(externalLinkField)
  }

  if(form.querySelectorAll(':invalid').length <= 1) {
    let ImagesValidation = document.querySelectorAll('#jsDropMessageRegion');
    
    if(jsInactivatedAtField.value != '' && jsActivatedAtField.value >= jsInactivatedAtField.value) {
      validateToElement(jsInactivatedAtField.nextElementSibling, 'A data de inativação deve ser maior quer a data de ativação!')
      returnForm();
    }

    if (ImagesValidation.length > 0) {
      SweetAlert.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Não é possível cadastrar um Mini Banner sem imagem!' });
      ImagesValidation[0].focus();
      returnForm();
    }

    for(var i = 1; i < 5; i++){
      let miniBannerField = document.getElementById(`jsAttachmentFields${i}`);
      let externalLinkField = document.getElementsByClassName(`jsLinkField${i}`)[0]
      let referenceLinkType  = miniBannerField.querySelector('#jsInputReferenceLinkType');
      let validateRegexLink = /^(ftp|http|https):\/\/[^ "]+$/.test(externalLinkField.value);

      if (referenceLinkType.value == 'external' && validateRegexLink != true && externalLinkField.value != '') {
        validateToElement(externalLinkField, 'O link deve estar no formato especificado')
        returnForm();
      }
    }

    validateBannersDayAndTime(event);

    function returnForm() {
      event.preventDefault();
      let button = event.srcElement;
      button.disabled = false;
    }
  }
}
