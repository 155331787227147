window.validateGeneralStoreSettings = function(event, form) {
  let button = event.srcElement;
  let serviceFeeValue = parseFloat(jsFieldServiceFee.value.replace('.', '').replace(',', '.'));
  button.disabled = true;
  event.preventDefault();
  removeValidityToElement(jsFieldServiceFeeCode);

  if (serviceFeeValue != 0 && serviceFeeValue != NaN && jsFieldServiceFeeCode.value == '') {
    validateToElement(jsFieldServiceFeeCode, 'Informe o código da taxa de serviço!');
    button.disabled = false;
    return ;
  }

  if (!jsOperationWithdrawal.checked && !jsOperationDelivery.checked) {
    SweetAlert.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Entrega e Retirada desmarcadas. Selecione um tipo de operação!'});
    button.disabled = false;
    return;
  };

  button.textContent = 'Salvando...';
  form.submit();
};

window.validatePoliceStoreSettings = function(event, form) {
  let button = event.srcElement;
  let limitSize = 65534;
  button.disabled = true;

  event.preventDefault();

  if(bodyFieldInput != undefined) {
    let quillSize = bodyField.children[0].innerHTML.length;

    if (quillSize > limitSize) {
      SweetAlert.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'O texto de políticas excede o limite de caracteres máximo'});

      button.disabled = false;
      return ;
    }
  };

  button.textContent = 'Salvando...';
  content = bodyField.children[0].innerHTML;
  bodyFieldInput.value = content;
  form.submit();
};

window.validateContactStoreSettings = function(event, form) {
  let button = event.srcElement;
  let emailRegex = /^[\w+\-.]+@[a-z\d\-]+(\.[a-z]+)*\.[a-z]+$/i
  button.disabled = true;
  event.preventDefault();
  removeValidityToElement(jsNotificationEmail);

  if (jsPhone.value == '' && jsWhatsapp.value == '') {
    SweetAlert.fire({
    icon: 'error',
    title: 'Oops...',
    text: 'Preencha pelo menos o "Telefone de contato da loja" ou "Whatsapp da loja"!'});
    button.disabled = false;
    return;
  };

  if(jsNotificationEmail.value != '' && !emailRegex.test(jsNotificationEmail.value)){
    validateToElement(jsNotificationEmail, 'Informe o email em um formato válido!');
    button.disabled = false;
    return ;
  };

  button.textContent = 'Salvando...';
  form.submit();
}

window.toogleLabelServiceFeeType = function(element) {
  if (element.value == 'percentage') {
    document.getElementById('jsLabelServiceFeeType').innerHTML = '%';
    jsFieldServiceFee.value = '0,00'
  } else {
    document.getElementById('jsLabelServiceFeeType').innerHTML = 'R$';
    jsFieldServiceFee.value = '0,00'
  }
}

window.formChanged = function() {
  let form = document.querySelector('form');
  window.formChanged = false;
  window.hiddenFieldInitialState = document.getElementById('bodyFieldInput');

  form.addEventListener('change', function() {
    window.formChanged = true;
  });

  quill.on('text-change', function() {
    window.formChanged = true;
  })
};

window.confirmUnsavedChanges = function(event, formNotSaved, href, currentTab) {
  let currentTabText = parseTabName(currentTab);
  let bodyFieldInput = document.getElementById('bodyFieldInput');
  event.preventDefault();

  if(bodyFieldInput != undefined) {
    window.formChanged ? formNotSaved = true : '';
  };

  if (formNotSaved) {
    SweetAlert.fire(
      {
        title: 'Alterações não salvas!',
        text: `Você fez alterações na página de configurações ${currentTabText} que ainda não foram salvas. Deseja sair sem salvar?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Voltar para edição!',
        cancelButtonText: 'Sim, sair sem salvar!'
      }).then((result) => {
      if (!result.isConfirmed) {
        window.location.href = href;
      }
    })
  }
  else {
    window.location.href = href;
  }
}

function parseTabName(currentTab) {
  const tabsNames = { contact: 'de contato', layout: 'de layout', policy: 'de políticas', products: 'de produto' }

  return tabsNames[currentTab] || 'gerais'
};

window.changeForDefaultColor = function(defaultColor, inputField) {
  inputField.value = defaultColor
  inputField.parentElement.style.color = defaultColor

  if(inputField.id == 'inputBackgroundColor') {
    initializeBackgroundColor();
  }
  else if(inputField.id == 'inputFontColor') {
    initializeFontColor();
  }
  else if(inputField.id == 'inputIconColor') {
    initializeIconColor();
  }
}

window.initializeBackgroundColor = function() {
  let color = inputBackgroundColor.value
  let mockupMultipleFields = document.getElementsByClassName('js-home-discount-badge')

  for (var i = 0; i < mockupMultipleFields.length; i++) {
    mockupMultipleFields[i].style.backgroundColor = color;
  }
}

window.initializeFontColor = function() {
  let color = inputFontColor.value
  let mockupMultipleFields = document.getElementsByClassName('js-home-discount-badge')

  for (var i = 0; i < mockupMultipleFields.length; i++) {
    mockupMultipleFields[i].style.color = color;
  }
}

window.initializeIconColor = function() {
  let color = inputIconColor.value
  let mockupMultipleFields = document.getElementsByClassName('js-home-percent-icon')

  for (var i = 0; i < mockupMultipleFields.length; i++) {
    mockupMultipleFields[i].style.color = color;
  }
}
