window.changeRocketProfile = function(name, initial_state, initial_value, element) {
  SweetAlert.fire(
    {
      title: 'Você tem certeza?',
      html: "Você tem certeza que deseja alterar o perfil de <strong>" + name + "</strong> de <strong>" + initial_value + "</strong> para <strong>" + element.selectedOptions[0].innerText + "</strong>",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, eu tenho certeza!',
      cancelButtonText: 'Não, cancelar!',
      focusConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        element.form.submit();
      }
      else {
        element.value = initial_state
      }
    }
  );
}
