window.infiniteScrollInitialize = function() {
  const loadArea = jsLoadAreaInfiniteScroll;

  async function addNamesToList() {
    var loadBtn = document.getElementById('jsLoadNextPage');

    if (!loadBtn) { return; }

    Rails.ajax({ url: loadBtn.href, type: 'GET' });
  }

  (function () {
    // Observe loadBtn
    const options = {
      // Use the whole screen as scroll area
      root: null,
      // Do not grow or shrink the root area
      rootMargin: "0px",
      // Threshold of 1.0 will fire callback when 100% of element is visible
      threshold: 1.0
    };

    const observer = new IntersectionObserver((entries) => {
      // Callback to be fired
      entries.forEach((entry) => {
        // Only add to list if element is coming into view not leaving
        if (entry.isIntersecting) {
          addNamesToList();
        }
      });
    }, options);

    observer.observe(loadArea);
  })();
}
