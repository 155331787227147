window.integrationLogsLoad = function (partner) {
  let queryString = window.location.href;
  let url = new URL(queryString);
  let c = url.searchParams.get("filters[service]");

  integrationLogsSetServiceOptions(partnerSelectField.value)

  if(c != null) {
    serviceSelectField.value = c
  }
};

window.integrationLogsSetServiceOptions = function (partner) {
  let options = serviceSelectField.options
  for (var i=options.length; i--;) {
    serviceSelectField.removeChild(options[i]);
  }

  if(partner != '') {
    let camelCasePartner = transformPartnerString(partner)

    options = JSON.parse(serviceSelectField.dataset[camelCasePartner])
    serviceSelectField.options.add(new Option('Selecione um serviço', '', true, true));
    for (var key in options) {
      serviceSelectField.options.add(new Option(options[key][0], options[key][1]));
    }
  }
  else {
    serviceSelectField.options.add(new Option('Selecione um parceiro', '', true, true));
  }
}

function transformPartnerString(partner) {
  return partner.replace(/_([a-z])/g, function(snakedLetter, letterToCamelize) {
    return letterToCamelize.toUpperCase();
  });
}
