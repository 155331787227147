window.validateFormOperationTimeExceptionNew = function(event) {
  let checkDelivery = document.getElementById('operation_time_exception_delivery_restriction');
  let checkWithdrawal = document.getElementById('operation_time_exception_withdrawal_restriction');
  let startTime = document.getElementById('operation_time_exception_start_time');
  let endTime = document.getElementById('operation_time_exception_end_time');
  removeValidityToElement(checkDelivery);
  removeValidityToElement(endTime.nextElementSibling);

  if(!checkDelivery.checked && !checkWithdrawal.checked) {
    validateToElement(checkDelivery, 'Selecione um tipo de restrição de operação para a exceção!');
    returnForm()
  };

  if(endTime.value != ''  && startTime.value >= endTime.value) {
    validateToElement(endTime.nextElementSibling, 'O Horário de término tem que ser maior que o de início!');
    returnForm()
  };

  function returnForm() {
    event.preventDefault();
    let button = event.srcElement;
    button.disabled = false;
  };
}
