window.initializeSlimSelect = function(element, args) {
  args = args || {}
  new SlimSelect(
    {
      select: element,
      settings: {
        searchPlaceholder: args['searchPlaceholder'],
        placeholderText: args['placeholderText'],
        searchText: 'Sem resultado',
        searchingText: 'Buscando...'
      }
    }
  )
}
