require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

import "../stylesheets/application"
import "bootstrap"
import "@fortawesome/fontawesome-pro/css/all"
import "@melloware/coloris/dist/coloris.css"

import Rails from "@rails/ujs";
window.Rails = Rails;

import SlimSelect from "slim-select";
window.SlimSelect = SlimSelect;

import Sortable from 'sortablejs';
window.Sortable = Sortable;

import Choices from 'choices.js';
window.Choices = Choices;

import SweetAlert from 'sweetalert2';
window.SweetAlert = SweetAlert.mixin({
  customClass: {
    confirmButton: 'btn btn-primary',
    cancelButton: 'btn btn-secondary'
  },
  buttonsStyling: false
});

const { EmojiButton } = require('@joeattardi/emoji-button');
window.EmojiButton = EmojiButton;

import Quill from 'quill';
window.Quill = Quill;

import Chart from 'chart.js/dist/chart.js';
window.Chart = Chart;

import IMask from 'imask';
window.IMask = IMask;

import "@melloware/coloris/dist/coloris.css";
import { coloris, init } from "@melloware/coloris";
init();
coloris({el: ".coloris",
         format: 'hex'});

const Flatpickr = require("flatpickr");
require("flatpickr/dist/l10n/pt.js").default.pt;
window.Flatpickr = Flatpickr;

import "./shared/banners"
import "./shared/buttons"
import "./shared/choises"
import "./shared/click_and_drop_image"
import "./shared/datatime_picker"
import "./shared/dynamic_select_filter"
import "./shared/edit_list_images"
import "./shared/emoji_picker"
import "./shared/get_n_parent_element"
import "./shared/infinite_scroll"
import "./shared/init_tooltips"
import "./shared/input_number_limit_characters"
import "./shared/masks"
import './shared/parsers'
import "./shared/quill"
import "./shared/remote_component"
import "./shared/remove_form_children_masks"
import "./shared/sidebar"
import "./shared/slim_select"
import "./shared/sortable"
import "./shared/spinner"
import './shared/toggle_attribute'
import './shared/toggle_class'
import './shared/validations'

import "./admin/admin_user_permissions"
import "./admin/top_and_category_banners"
import "./admin/invoice_reports"
import "./admin/manager_aisles"
import "./admin/manager_banners"
import "./admin/manager_products"
import "./admin/manager_store_categories"
import "./admin/mini_banners"
import "./admin/operation_times"
import "./admin/operation_times_exception"
import "./admin/orders_reports"
import "./admin/orders"
import "./admin/store_products"
import "./admin/store_settings"
import "./admin/temporary_product"

import "./spaceport/manager_integration_logs"
import "./spaceport/manage_contracts"
import "./spaceport/manager_master_categories"
import "./spaceport/manage_integrations"
import "./spaceport/rockets"
import "./spaceport/temporary_products"

import 'quill/dist/quill.snow.css';
import 'flatpickr/dist/flatpickr.css';

window.bootstrap = require('bootstrap');
