window.unfreezeAll = function(button, event, client, status) {
  let selectedClient = client.options[client.selectedIndex];
  let clientName = selectedClient.innerHTML;
  let selectedStatus = status.options[status.selectedIndex];
  event.preventDefault();
  button.disabled = true;

  if(selectedClient.value != '' && selectedStatus.value == '-2') {
    SweetAlert.fire({
      title: 'Você tem certeza?',
      text: 'Você realmente deseja descongelar TODOS os produtos do cliente: ' + clientName,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, eu tenho certeza!',
      cancelButtonText: 'Não, cancelar!',
      focusConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        button.parentElement.submit();
      } else {
        return;
      }
    })
  } else {
      SweetAlert.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Para descongelar todos os produtos é necessário selecionar um cliente e o status: congelado'});
        return;
  }
};

window.buttonUnfreezeAll = function(client, status) {
  let button = jsUnfreezeAll;
  let frozenProducts = document.getElementById('jsTemporaryProductsCard')
  let selectedClient = client.options[client.selectedIndex];
  let selectedStatus = status.options[status.selectedIndex];

  if(selectedClient.value != '' && selectedStatus.value == '-2' && frozenProducts == null && button.disabled) {
    button.disabled = false;
  } else if(!button.disabled){
    button.disabled = true;
  }
};

window.disableButtonsUntilSubmit = function() {
  let buttons = document.getElementsByClassName('js-disabling-button')

  for (var i = 0; i < buttons.length; i++) {
    buttons[i].disabled = true;
  };
};
