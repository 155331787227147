window.managerOrderForward = function(button, orderDeliveredType, orderState) {
  button.disabled = true
  if (orderState == 'confirming') {
    if (selectSeparator.value == '') {
      selectSeparator.focus();
      warning('sem um separador!')
    } if (inputDryVolumes.value == 0 && inputColdVolumes.value == 0) {
      selectVolumes.focus();
      warning('sem nenhum volume!')
    } else if (parseInt(inputDryVolumes.value) + parseInt(inputColdVolumes.value) > inputDryVolumes.max) {
      selectVolumes.focus();
      warning(`se a quantidade de volumes for maior do que ${inputDryVolumes.max}`)
    } else { button.parentElement.submit(); }
  } else if (orderDeliveredType == 'delivery' ) {
    if (orderState == 'delivering' && selectDeliveryman.value == '') {
      selectDeliveryman.focus();
      warning('sem um entregador!')
    } else { button.parentElement.submit(); }
  } else { button.parentElement.submit(); }

  function warning(message) {
    button.disabled = false
    SweetAlert.fire({ text: `O pedido não pode passar de fase ${message}`, icon: 'error' })
    button.parentElement.addEventListener('submit', (e) => { e.preventDefault(); })
  }
}

window.addCartItem = function(element) {
  element = getNParentElement(element, 2)
  if(jsAddedItems.querySelector(`#${element.id}`) == null) {
    jsAddedItems.appendChild(element);

    element.querySelector('.jsForm').classList.remove('d-none')
    element.querySelector('.jsShow').classList.add('d-none')
  } else { SweetAlert.fire({ text: `O item já foi selecionado`, icon: 'error' }) }
}

window.removeCartItem = function(element) {
  jsAddedItems.removeChild(getNParentElement(element, 2))
}

window.printOrder = function(order_code) {
  window.addEventListener('beforeprint', () => {
    document.title = "pedido_" + order_code;
  });
  window.print();
  window.addEventListener('afterprint', () => {
    document.title = "ECresceVendas";
  });
}

window.cancelOrder = function(event, order_code) {
  event.preventDefault();
  SweetAlert.fire(
    {
      title: 'Cancelamento de pedido',
      text: `Você está preste a cancelar o pedido ${order_code}, se tem certeza clique em "Sim, cancelar pedido."`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, cancelar pedido!',
      cancelButtonText: 'Não, voltar!',
      focusConfirm: true
    }).then((result) => {
      if(result.isConfirmed) {
        jsFormCancelOrder.submit();
      }
    }
  );
}

function cancelOrderByAllItemsNotFoundOrGiveUp() {
  SweetAlert.fire(
    {
      title: 'Nenhum item do pedido foi encontrado?',
      text: 'Na ausência de todos os itens no pedido, você pode cancelar o pedido clicando no botão "Cancelar Pedido".',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Cancelar Pedido',
      cancelButtonText: 'Incluir itens',
      focusConfirm: true
    }).then((result) => {
      if(result.isConfirmed) {
        jsFormCancelOrder.submit();
      }
    }
  );
}

window.jsSetOrderEditing = function() {
  elements = document.getElementsByClassName('jsOrderEditing')
  jsOrderAllItemsNotFoundButton.classList.add('d-none');

  if (elements[0].configurable == undefined) {
    for (let element of elements) {
      element.classList.toggle('d-none')
      element.configurable = false
    }
  }
}

window.jsChangeCartItemsRequestQuantity = function(itemId) {
  let cartItemRequestQuantity = document.getElementById(`jsCartItemRequestQuantity${itemId}`)
  let cartItemFoundQuantity = document.getElementById(`jsCartItemFoundQuantity${itemId}`)

  cartItemFoundQuantity.max=cartItemRequestQuantity.value

  if (cartItemFoundQuantity.value > cartItemRequestQuantity.value) {
    cartItemFoundQuantity.value = cartItemRequestQuantity.value
  }
}

window.jsChangeFoundQuantity = function(itemId) {
  let notFoundCheckBox = document.getElementById(`jsNotFoundCheckBox${itemId}`)
  let giveUpCheckBox = document.getElementById(`jsGiveUpCheckBox${itemId}`)
  let cartItemFoundQuantity = document.getElementById(`jsCartItemFoundQuantity${itemId}`)

  if (cartItemFoundQuantity.value == 0) {
    notFoundCheckBox.checked = true
    giveUpCheckBox.checked = false
  } else if (cartItemFoundQuantity.value > 0) {
    notFoundCheckBox.checked = false
  }
}

window.jsChangeGiveUpNotFoundCheckBox = function(element, itemId) {
  let notFoundCheckBox = document.getElementById(`jsNotFoundCheckBox${itemId}`)
  let giveUpCheckBox = document.getElementById(`jsGiveUpCheckBox${itemId}`)
  let cartItemFoundQuantity = document.getElementById(`jsCartItemFoundQuantity${itemId}`)
  let cartItemRequestQuantity = document.getElementById(`jsCartItemRequestQuantity${itemId}`)

  if(element.checked == true) {
    if(element == notFoundCheckBox) { giveUpCheckBox.checked = false }
    else { notFoundCheckBox.checked = false }
    cartItemFoundQuantity.value = 0
  } else { cartItemFoundQuantity.value = cartItemRequestQuantity.value }
}

window.orderNeedToBeCanceled = function(order_state) {
  let countAllNotFoundCheckBoxes = jsAllNotFoundCheckBoxes.length
  let checkedCount = 0
  let confirmingButton = document.getElementById('buttonOrderForward')

  for(var i = 0; i < countAllNotFoundCheckBoxes || i < 1; i++) {
    let eachOneNotFoundCheckBox = (countAllNotFoundCheckBoxes > 1) ? jsAllNotFoundCheckBoxes[i].children[1] : jsAllNotFoundCheckBoxes.children[1];
    let eachOneGiveUpCheckBox = (countAllNotFoundCheckBoxes > 1) ? jsAllGiveUpCheckBoxes[i].children[1] : jsAllGiveUpCheckBoxes.children[1];
    if ((eachOneNotFoundCheckBox.checked || eachOneGiveUpCheckBox.checked) && order_state == 'confirming') {
      checkedCount ++;
      if(checkedCount == countAllNotFoundCheckBoxes || checkedCount == 1 && countAllNotFoundCheckBoxes == undefined) {
        cancelOrderByAllItemsNotFoundOrGiveUp();
        jsOrderAllItemsNotFoundButton.classList.remove('d-none');
        confirmingButton.classList.add('d-none');
      }
    }
  }
}
