window.deleteAisle = function(store_setting_id, aisle_id) {
  SweetAlert.fire(
    {
      title: 'Você tem certeza?',
      text: 'Você não poderá recuperar este corredor!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sim, eu tenho certeza!',
      cancelButtonText: 'Não, cancelar!',
      focusConfirm: true
    }).then((result) => {
      if (result.isConfirmed) {
        toggleSpinner('js-load-spinner')
        Rails.ajax(
          { url: `/ecommerce/admin/stores/${store_setting_id}/aisles/${aisle_id}`, type: 'DELETE',
            error: (data) => {
              toggleSpinner('js-load-spinner')
          }, success: (data) => {
            aisle = document.querySelector(`[data-id="${aisle_id}"]`)
            rescueSubcategories(aisle)
            aisle.parentElement.removeChild(aisle);
            toggleSpinner('js-load-spinner')
          }}
        )
      }
    }
  );
  function rescueSubcategories(aisle) {
    subcategories = aisle.getElementsByClassName('list-group-item')
    withoutAisle = document.getElementById('list-of-subcategories-without-aisle')
    Array.from(subcategories).forEach(subcategory => withoutAisle.appendChild(subcategory))
  }
}
