window.initializeChoises = function(element) {
  new Choices(element, {
    removeItemButton: true,
    noResultsText: 'Nenhum resultado encontrado',
    noChoicesText: 'Sem opções para escolher',
    itemSelectText: 'Pressione para selecionar'
  });
}

window.loadChoicesForProductOrtographicVariantNames = function() {
  let orthographicVariants = jsOrthograpicVariantNamesChoices
  new Choices(orthographicVariants, {
    removeItemButton: true,
    addItemText: (value) => {
      return `Pressione Enter para adicionar <b>"${value}"</b>`;
    },
  });
}
