window.loadMaskMoney = function() {
  let moneyInputs = document.getElementsByClassName('jsMaskMoney')
  Array.from(moneyInputs).forEach(element => {
    applyMaskMoney(element);
  });
}

window.applyMaskMoney = function(element) {
  IMask(element, {
    mask: 'num',
    blocks: {
      num: {
        mask: Number,
        thousandsSeparator: '.',
        radix: ',',
        scale: 2,
        padFractionalZeros: true,
      }
    }
  });
}

window.loadMaskDecimal = function() {
  let decimalInputs = document.getElementsByClassName('jsMaskDecimal')

  Array.from(decimalInputs).forEach(element => {
    IMask(element, {
      mask: Number,

      scale: element.dataset['fractiondigits'] || 2,
      signed: false,
      thousandsSeparator: '',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: ',',
      mapToRadix: ['.']
    });
  });
}

window.loadMaskInteger = function() {
  let integerInputs = document.getElementsByClassName('jsMaskInteger')

  Array.from(integerInputs).forEach(element => {
    IMask(element, {
      mask: Number,

      scale: 0,
      signed: false,
      thousandsSeparator: '.',
      padFractionalZeros: true,
      normalizeZeros: true,
      radix: ',',
      mapToRadix: ['.']
    });
  });
}

window.loadMaskPhoneNumber = function() {
  let phoneInputs = document.getElementsByClassName('jsMaskPhoneNumber')

  Array.from(phoneInputs).forEach(element => {
    IMask(element, {
      mask: [{ mask: '(00) 0000-0000' }, { mask: '(00) 0 0000-0000'}]
    });
  });
}
