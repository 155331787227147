window.graphInvoice = function(dataInvoice, dataBilling) {
  const ctx1 = document.getElementById('js_graph_invoices');
  new Chart(ctx1, {
    type: 'line',
    data: dataInvoice,
    options: {
      responsive: true,
      scales: {
        y: {
          stacked: true,
          min: 0,
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
              return label;
            }
          }
        },
      }
    }
  });

  const ctx2 = document.getElementById('js_graph_billing');
  new Chart(ctx2, {
    type: 'line',
    data: dataBilling,
    options: {
      responsive: true,
      scales: {
        y: {
          stacked: true,
          min: 0,
          grid: {
            display: false,
          },
        },
        x: {
          grid: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: true
        },
        tooltip: {
          callbacks: {
            label: function(context) {
              let label = context.dataset.label || '';

              if (label) {
                  label += ': ';
              }
              label += new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(context.parsed.y);
              return label;
            }
          }
        },
      }
    }
  });
}
